import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, parse, StaticQuery, Link } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import Sticky from 'react-sticky-el'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import BackgroundVideo from '../assets/donbranco_mainloop.mp4'
import FlexibleContent from '../components/FlexibleContent'
import BackgroundPageImage from '../components/BackgroundPageImage'

// page template create pages with acf
export const PageTemplate = ({
  title,
  acf,
  parentPath,
  lang,
  translations,
}) => {
  let parentLink = null
  if (parentPath) {
    parentLink = (
      <Link to={parentPath} className="arrow-back back-link">
        <span className="icon-arrow-left" />
        <FormattedMessage id="back" />
      </Link>
    )
  }
  return (
    <article className="page">
      <header className="narrow page-header">
        {parentLink}
        <h1>{acf.title}</h1>
        {ReactHtmlParser(acf.lead_text)}
      </header>
      <FlexibleContent
        data={acf.flexible_content_page}
        lang={lang}
        translations={translations}
      />
    </article>
  )
}

// creating front page
export const FrontPageTemplate = ({ title, acf }) => (
  <article className="front-page">
    <header className="frontpage-header">
      <video autoPlay playsInline muted loop>
        <source src={BackgroundVideo} type="video/mp4" />
      </video>
    </header>

    <section className="sticky-kalvo">
      <Sticky
        boundaryElement=".sticky-kalvo"
        hideOnBoundaryHit={false}
        style={{ position: 'relative' }}
      >
        <div className="heading-padding">
          <h1 className="narrow">{acf.title}</h1>
        </div>
      </Sticky>
    </section>
    <section className="section narrow leadtext">
      {ReactHtmlParser(acf.lead_text)}
    </section>

    {/* getting acf content */}
    <FlexibleContent data={acf.flexible_content_page} frontpage />
  </article>
)

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

// setting up pages
const Page = ({ data }) => {
  const { wordpressPage: page } = data
  const { title, lang, translations, acf, path, parent_element } = page
  const { image, position } = acf

  const imageBackground =
    typeof image !== 'undefined' && image !== null ? image : false
  const imagePosition =
    typeof position !== 'undefined' && position !== null ? position : false
  const displayBackgroundImage =
    imageBackground && imagePosition ? (
      <BackgroundPageImage image={imageBackground} position={imagePosition} />
    ) : (
      ''
    )

  if (path === '/' || path === '/fi/') {
    return (
      <Layout lang={lang} translations={translations}>
        {displayBackgroundImage}
        <FrontPageTemplate title={title} acf={acf} />
      </Layout>
    )
  }

  const parentPath = parent_element !== null ? parent_element.path : false

  return (
    <Layout lang={lang} translations={translations}>
      <Helmet title={`${title} | Don & Branco`} />
      {displayBackgroundImage}
      <PageTemplate
        title={title}
        acf={acf}
        parentPath={parentPath}
        lang={lang}
        translations={translations}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      path
      lang
      translations {
        en
        fi
      }
      parent_element {
        path
      }
      acf {
        title
        lead_text
        position
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        flexible_content_page {
          __typename
          ... on Node {
            ... on WordPressAcf_wysiwyg {
              wysiwyg
              class_name
            }

            ... on WordPressAcf_two_images {
              image_sizes
              class_name
              first_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }

              second_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_text_in_columns {
              class_name
              columns {
                column_title
                column_title_link
                lines {
                  text
                }
              }
            }

            ... on WordPressAcf_image {
              full_width_image
              image_size
              class_name
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_links_to_references {
              class_name
              references_page_link
              references {
                marketing_text
                link
                link_text
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_video {
              class_name
              video_file {
                localFile {
                  url
                }
              }
              full_width_video
            }

            ... on WordPressAcf_video_and_image {
              class_name
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              video {
                localFile {
                  url
                }
              }
              alignment
            }

            ... on WordPressAcf_staff {
              class_name
              peeps {
                title
                name
                tel
                email
              }
            }

            ... on WordPressAcf_form {
              id
              form
            }

            ... on WordPressAcf_blog {
              id
              search_tags {
                tag
              }
            }

            ... on WordPressAcf_open_job_positions {
              id
              wysiwyg
              title
              positions {
                link
                position
              }
            }
          }
        }
      }
    }
  }
`
